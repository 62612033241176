// ENV Variables
import env from "@/../env.json";

// Helpers
import get_query_param from "@/helpers/get_query_param.js";
import get_device_environment from "@/helpers/get_device_environment.js";

export default () => {
    env.utm_variables.forEach((key) => {
        const param_value = get_query_param(key);
        const stored_value = localStorage.getItem(key);

        if (param_value && (!stored_value || stored_value === "")) {
            localStorage.setItem(key, param_value);
        }
    });

    // Special cases
    //
    const utm_source = localStorage.getItem("utm_source");

    if (!utm_source || utm_source === "") {
        const meta_utm_source = document.querySelector(
            'meta[name="utm.source"]',
        )?.content;
        if (meta_utm_source) {
            localStorage.setItem("utm_source", meta_utm_source);
        }
    }

    //
    const utm_content = localStorage.getItem("utm_content");
    const meta_utm_content = document.querySelector(
        'meta[name="utm.content"]',
    )?.content;

    if ((!utm_content || utm_content === "") && meta_utm_content) {
        localStorage.setItem("utm_content", meta_utm_content);
    }

    //
    const utm_device = localStorage.getItem("utm_device");
    if (!utm_device || utm_device === "") {
        const { os } = get_device_environment();
        localStorage.setItem("utm_device", os);
    }

    //
    const utm_version = localStorage.getItem("utm_version");
    if (!utm_version || utm_version === "") {
        localStorage.setItem("utm_version", env.version.web);
    }
};
