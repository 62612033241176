<template>
    <div class="header" v-if="top_navigation_is_visible">
        <nav class="header-nav" :style="{ ...header_background_class }">
            <div
                class="container container-default header-wrapper"
                :style="`padding-top: max(var(--safe-area-inset-top, 0px), 7px)`"
            >
                <div class="w-fit flex gap-2 justify-center">
                    <link_component
                        v-if="!user_is_logged"
                        :title="gt('Home')"
                        :path="!user_is_logged ? '/' : '/journey'"
                    >
                        <img
                            :src="logo_icon"
                            class="h-10 rounded-lg cursor-pointer"
                            :alt="gt('memeonlyme')"
                            :title="gt('memeonlyme')"
                            :aria-label="gt('memeonlyme')"
                        />
                    </link_component>

                    <template
                        v-if="
                            is_vue_running_on() === 'memeonlyme.com' &&
                            !user_is_logged
                        "
                    >
                        <ul class="left-menu">
                            <li>
                                <link_component
                                    :classes="[
                                        'nav-item',
                                        is_active(['/routes', '/route'])
                                            ? 'active'
                                            : '',
                                    ]"
                                    :title="gt('Routes')"
                                    :path="'/routes'"
                                >
                                    <i class="fi fi-rr-playing-cards"></i>
                                    {{ gt("Routes") }}
                                </link_component>
                            </li>

                            <li class="hidden md:flex">
                                <link_component
                                    :classes="[
                                        'nav-item',
                                        is_active(['/blog']) ? 'active' : '',
                                    ]"
                                    :title="gt('Posts')"
                                    :path="'/blog'"
                                >
                                    <i class="fi fi-rr-book-open-cover"></i>
                                    {{ gt("Posts") }}
                                </link_component>
                            </li>
                        </ul>
                    </template>
                    <template
                        v-if="
                            user_is_logged &&
                            (go_back || title || Navigation.loading)
                        "
                    >
                        <ul class="left-menu">
                            <li v-if="go_back">
                                <link_component
                                    :classes="['nav-item', '!text-slate-900']"
                                    :path="go_back"
                                >
                                    <i class="fi fi-rr-angle-left"></i>
                                    {{ gt("Go Back") }}
                                </link_component>
                            </li>
                            <li v-if="title">
                                <link_component
                                    :classes="['nav-item', 'active']"
                                    v-if="!Navigation.loading"
                                >
                                    {{ title }}
                                </link_component>

                                <a
                                    :class="['nav-item', 'active']"
                                    v-if="Navigation.loading"
                                >
                                    <loader_component size="small" />
                                </a>
                            </li>
                        </ul>
                    </template>
                </div>

                <div class="w-fit flex">
                    <template v-if="user_is_logged">
                        <ul
                            class="flex w-full gap-1 list-none items-center justify-end"
                        >
                            <li>
                                <user_menu_component />
                            </li>
                        </ul>
                    </template>

                    <template v-if="!user_is_logged">
                        <ul
                            class="flex w-full gap-1 list-none items-center justify-end"
                        >
                            <li>
                                <link_component
                                    :title="gt('Sign In')"
                                    :classes="['nav-item', 'active']"
                                    :path="'/login'"
                                >
                                    <i
                                        class="fi fi-rr-user leading-[0] text-xl"
                                    ></i>
                                    {{ gt("Sign In") }}
                                </link_component>
                            </li>
                        </ul>
                    </template>
                </div>
            </div>
        </nav>
    </div>

    <!-- Player -->
    <teleport to="body">
        <player_component />
    </teleport>

    <!--  Notifications -->
    <teleport to="body">
        <rewards_notification />
        <language_notification />
        <user_expired_notification />

        <notifications_component />
        <app_profile_modal_component v-if="user_is_logged" />
    </teleport>
</template>

<script setup>
// Components
import {
    defineAsyncComponent,
    onMounted,
    onUnmounted,
    ref,
    computed,
    watch,
} from "vue";

const app_profile_modal_component = defineAsyncComponent(
    () => import("@/components/wrappers/modals/menu/index.vue"),
);

const player_component = defineAsyncComponent(
    () => import("@/components/app/player/index.vue"),
);

const notifications_component = defineAsyncComponent(
    () => import("@/components/app/notifications/index.vue"),
);

const loader_component = defineAsyncComponent(
    () => import("@/components/commons/loader.vue"),
);

const user_menu_component = defineAsyncComponent(
    () => import("./components/header/user_menu.vue"),
);

const language_notification = defineAsyncComponent(
    () => import("./components/header/language_notification.vue"),
);
const rewards_notification = defineAsyncComponent(
    () => import("./components/header/rewards_notification.vue"),
);

const user_expired_notification = defineAsyncComponent(
    () => import("./components/header/user_expired_notification.vue"),
);

const link_component = defineAsyncComponent(
    () => import("@/components/commons/link.vue"),
);

// Translation needs
import { useI18n } from "vue-i18n";
import get_translation from "@/helpers/get_translation";
const { t } = useI18n();
const gt = (text, args) => get_translation(t, text, args);

// Stores
import UserStore from "@/stores/user";
import NavigationStore from "@/stores/top_navigation";

// Images
import logo_icon from "@/assets/logos/logo-icon.jpeg";

// Helpers
import get_words from "@/helpers/get_words";
import get_shortened_text from "@/helpers/get_shortened_text";
import track from "./methods/track.js";
import is_vue_running_on from "@/helpers/is_vue_running_on.js";

// Handlers
import handle_unauthenticated_event from "./handlers/handle_unauthenticated_event.js";
import handle_public_notifications from "./handlers/handle_public_notifications.js";
import handle_private_notifications from "./handlers/handle_private_notifications.js";

// Variables
const User = UserStore();
const Navigation = NavigationStore();

const is_scrolled = ref(false);
const last_scroll_position = ref(0);

// Computed
const title = computed(() =>
    get_shortened_text(get_words(Navigation.title, 5), 20),
);

const go_back = computed(() => Navigation.go_back);
const top_navigation_was_update_at = computed(() => Navigation.updated_at);
const top_navigation_is_visible = computed(() => Navigation.visible);
const user_is_logged = computed(() => User.is_logged);

const header_background_class = computed(() => {
    const { background } = Navigation;
    let background_color = background === "light" ? "255,255,255" : "0,0,0";
    let style = `rgba(${background_color},${0.1})`;

    return {
        backgroundColor: style,
    };
});

// Watchers
watch(top_navigation_was_update_at, () => {
    set_css_top_navigation_height_variable();
});

// Methods
function set_css_top_navigation_height_variable() {
    const navigation = document.querySelector(".header-nav");
    const wrapper = document.querySelector(".wrapper-for-app-pages");

    if (!navigation) {
        return;
    }

    const top_safe_space = navigation.offsetHeight + 14 + "px";

    if (wrapper) {
        wrapper.style.paddingTop = top_safe_space;
    }

    localStorage.setItem("top_safe_space", top_safe_space);
}

function is_active(path = []) {
    return path.some((route) => {
        if (route === "/") {
            return window.location.pathname === "/";
        }

        return window.location.pathname.startsWith(route);
    });
}

function handle_scroll() {
    const tolerance = 50;
    const current_scroll = window.scrollY;
    const open =
        current_scroll <= last_scroll_position.value ||
        current_scroll <= tolerance;

    if (!Navigation.disabled) {
        Navigation.update({
            visible: open,
        });
    }

    is_scrolled.value = current_scroll > tolerance;
    last_scroll_position.value = current_scroll;
}

// Lifecycle
onMounted(() => {
    track();
    setTimeout(() => {
        set_css_top_navigation_height_variable();
    }, 150);

    handle_unauthenticated_event({ User });

    handle_public_notifications();
    handle_private_notifications();

    window.addEventListener("scroll", handle_scroll);
});

onUnmounted(() => {
    window.removeEventListener("scroll", handle_scroll);
});
</script>
<style lang="scss">
@use "./styles/header.scss";
</style>
