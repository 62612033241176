<template>
    <template v-if="show_title">
        <img
            :src="logo_icon"
            class="logo mt-[100px]"
            :alt="gt('memeonlyme')"
            :title="gt('memeonlyme')"
            :aria-label="gt('memeonlyme')"
        />

        <title_component>
            <template #title>
                {{ gt("Join the community") }}
            </template>
            <template #subtitle>
                {{
                    gt("Be part of a community that prioritizes mental health.")
                }}
            </template>
        </title_component>
    </template>

    <form_register_component
        :on_response="on_register_response"
    ></form_register_component>
</template>
<script setup>
// Libs
import { defineAsyncComponent, onBeforeMount, toRefs, computed } from "vue";
import { useRouter } from "vue-router";

// Components
const title_component = defineAsyncComponent(
    () => import("@/components/commons/title.vue"),
);

const form_register_component = defineAsyncComponent(
    () => import("@/components/commons/forms/register.vue"),
);

// Stores
import UserStore from "@/stores/user";
import BottomNavigationStore from "@/stores/bottom_navigation";
import TopNavigationStore from "@/stores/top_navigation";

// Helpers
import redirect_if_necessary from "@/helpers/redirect_if_necessary";
import is_native_framework from "@/helpers/is_native_framework.js";

// Images
import logo_icon from "@/assets/logos/logo-icon.jpeg";

// Translation needs
import { useI18n } from "vue-i18n";
import get_translation from "@/helpers/get_translation";
const { t } = useI18n();
const gt = (text, args) => get_translation(t, text, args);

// Variables
const props = defineProps({
    // Show or not
    show_title: {
        type: Boolean,
        default: true,
    },

    show_top_navigation: {
        type: Boolean,
        default: false,
    },

    show_bottom_navigation: {
        type: Boolean,
        default: false,
    },

    // Callbacks
    on_response: {
        type: Function,
        default: null,
    },
});

const User = UserStore();
const BottomNavigation = BottomNavigationStore();
const TopNavigation = TopNavigationStore();

const $router = is_native_framework() ? useRouter() : null;
const { show_title, show_top_navigation, show_bottom_navigation, on_response } =
    toRefs(props);

// Computed variables
const is_logged = computed(() => User.is_logged);

// Methods
function redirect_if_is_logged() {
    if (!is_logged.value) {
        return;
    }

    if (!$router) {
        window.location.href = "/journey";
    } else {
        $router.push("/journey");
    }
}

function set_title_and_navigation() {
    TopNavigation.update({
        loading: false,
        visible: show_top_navigation.value,
        go_back: "/login",
    });

    BottomNavigation.update({
        visible: show_bottom_navigation.value,
    });
}

function on_register_response({ success }) {
    if (success && !on_response.value) {
        redirect_if_necessary({
            path: "/journey",
            $router,
        });
        return;
    }

    if (on_response.value) {
        on_response.value({ success });
    }
}

// Lifecycle
onBeforeMount(() => {
    redirect_if_is_logged();
    set_title_and_navigation();
});
</script>
<style lang="scss" scoped>
@use "styles/index.scss";
</style>
