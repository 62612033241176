<template>
    <upgrade_page v-if="!success && !error" />
    <error_page v-else-if="error" />
    <thanks_page v-else-if="success" />
    <alert_component v-else>
        <template #title>{{ gt("Something wrong happened") }}</template>
        <template #content>
            <p>{{ gt("Try again later... or contact us.") }}</p>
        </template>
    </alert_component>
</template>

<script setup>
// Components
import { defineAsyncComponent, onBeforeMount, watch, ref } from "vue";
import { useRouter, useRoute } from "vue-router";

const alert_component = defineAsyncComponent(
    () => import("@/components/commons/alert.vue"),
);

const upgrade_page = defineAsyncComponent(
    () => import("./modules/upgrade.vue"),
);

const error_page = defineAsyncComponent(() => import("./modules/error.vue"));

const thanks_page = defineAsyncComponent(() => import("./modules/thanks.vue"));

// Translation needs
import { useI18n } from "vue-i18n";
import get_translation from "@/helpers/get_translation";
const { t } = useI18n();
const gt = (text, args) => get_translation(t, text, args);

// Stores
import UserStore from "@/stores/user";
import BottomNavigationStore from "@/stores/bottom_navigation";
import TopNavigationStore from "@/stores/top_navigation";

// Helpers
import get_query_param from "@/helpers/get_query_param.js";
import is_native_framework from "@/helpers/is_native_framework.js";

// Variables
const User = UserStore();
const BottomNavigation = BottomNavigationStore();
const TopNavigation = TopNavigationStore();

const $router = is_native_framework() ? useRouter() : null;
const $route = is_native_framework() ? useRoute() : null;
const success = ref(get_query_param("success"));
const error = ref(get_query_param("error"));
const { is_logged } = User;

// Watchers
watch($route, () => {
    success.value = get_query_param("success");
    error.value = get_query_param("error");
});

// Methods
function redirect_if_is_not_logged() {
    if (is_logged) {
        return;
    }

    if (!$router) {
        window.location.href = "/login";
    } else {
        $router.push("/login");
    }
}

function set_title_and_navigation() {
    TopNavigation.update({
        loading: false,
        visible: true,
        title: gt("Subscribe"),
        go_back: "/journey",
    });

    BottomNavigation.update({
        visible: true,
        active: "journey",
    });
}

// Lifecycle
onBeforeMount(() => {
    redirect_if_is_not_logged();
    set_title_and_navigation();
});
</script>
