<template>
    <div class="flex flex-col gap-2">
        <input_component
            :settings="input_search"
            @update:value="
                ($event) => {
                    search = $event;
                    debounce_get_posts_by_search();
                }
            "
        />
    </div>

    <div class="flex flex-col gap-2" v-if="not_results_found">
        <alert_component>
            <template #message>
                {{ gt("We did not find what you are looking for.") }}
            </template>
        </alert_component>

        <div class="buttons flex flex-wrap gap-2">
            <a
                :class="['btn', 'btn-light', '!w-fit', '!grow-0']"
                :href="url({ path: '/suggestions' })"
                :title="
                    gt('Would you like us to create an article on something?')
                "
            >
                <i class="fi fi-rr-add-document"></i>
                {{ gt("Let us know") }}
            </a>
        </div>
    </div>

    <posts_component
        :posts="posts"
        :with_pagination="true"
        :posts_pagination="posts_pagination"
        :on_go_to_page="get_posts_by_search"
    />
</template>

<script setup>
// Components
import { defineAsyncComponent } from "vue";

const posts_component = defineAsyncComponent(
    () => import("@/components/app/posts/index.vue"),
);

const alert_component = defineAsyncComponent(
    () => import("@/components/commons/alert.vue"),
);

const input_component = defineAsyncComponent(
    () => import("@/components/commons/input.vue"),
);

// Helpers
import url from "@/helpers/url";
import get_locale from "@/helpers/get_locale";
import track from "@/helpers/track.js";

// Translation needs
import { useI18n } from "vue-i18n";
import get_translation from "@/helpers/get_translation";
const { t } = useI18n();
const gt = (text, args) => get_translation(t, text, args);

// Libs
import { onMounted, ref, computed } from "vue";
import { debounce } from "lodash-es";
import Post from "@/methods/for_posts";

// Stores
import TopNavigationStore from "@/stores/top_navigation.js";

// Variables
const TopNavigation = TopNavigationStore();

const search = ref("");
const posts = ref([]);
const posts_pagination = ref({});
const input_search = ref({
    label: gt("Search for a symptom you might have..."),
    placeholder: gt("Example: test..."),
    name: "search",
    type: "text",
    default: search.value,
    icon: {
        name: "fi-rr-search",
    },
});

// Computed variables
const not_results_found = computed(() => {
    return posts.value.length === 0;
});

// Methods
const get_posts_by_search = async function (args = {}) {
    args.per_page = args.per_page || 5;
    args.with_relations = ["createdBy"];
    args.with_properties = ["images", "stats"];
    args.filters = {
        search: search.value,
        diffuse: 1,
        language: get_locale(),
    };

    // Track
    twq("event", "tw-okgqx-owq4l", {
        search_string: search.value,
    });

    fbq("track", "Search", {
        search_string: search.value,
    });

    track({
        event: "user_made_a_post_search",
        append: {
            search: search.value,
            language: get_locale(),
        },
    });

    const { records, pagination } = await Post.get(args);

    posts.value = records || [];
    posts_pagination.value = pagination || {};
};

const get_params_and_use_it = async function () {
    const params = new URLSearchParams(window.location.search);
    const keyword = params.get("keyword", null);

    if (keyword && keyword.length > 0) {
        search.value = keyword;
        input_search.value.default = keyword;
        input_search.value.value = keyword;
    }
};

const get_records = async function () {
    await get_posts_by_search();
};

const debounce_get_posts_by_search = debounce(get_posts_by_search, 700);

// Lifecycle
onMounted(async () => {
    TopNavigation.update({
        loading: false,
        title: gt("Blog"),
        go_back: url({ path: "/" }),
    });

    await get_params_and_use_it();
    await get_records();
});
</script>
